<template>
  <div>
    <div v-if="documents_data.count > 0" class="content__title content__title--party content__title--start">
      <h2 class="title">Список документов</h2>
      <router-link
        :to="{ name: 'profile-docs-create' }"
        class="link link--right"
      >
        <span>добавить</span>
      </router-link>
    </div>
    <div class="data-cards">
      <router-link v-if="documents_data.count < 1" :to="{ name: 'profile-docs-create' }" class="data-cards__item data-cards__item--empty">
        <div class="data-cards__title"><span>Документы</span></div>
        <div class="data-cards__picture">
          <img src="@/assets/img/profile/doc-def-icon.svg" alt="">
          <img src="@/assets/img/profile/doc-color-icon.svg" alt="">
        </div>
        <div class="data-cards__text">
          На данный момент данные о ваших документы не заполнены, нажмите сюда, чтобы заполнить
        </div>
      </router-link>
      <div v-else class="data-cards__item" v-for="item in documents_data.results" :key="item.id">
        <div class="data-cards__title">
          <span>{{ item.type_name }}</span>
          <router-link :to="{ name: 'profile-docs-edit', params: { id: item.id } }">редактировать</router-link>
        </div>
        <div class="data-cards__picture">
          <img src="@/assets/img/profile/doc-color-icon.svg" alt="">
        </div>
        <div class="data-cards__text"><b>Серия:</b>&nbsp; {{ item.series }}</div>
        <div class="data-cards__text"><b>Номер:</b>&nbsp; {{ item.number }}</div>
        <div v-if="item.scan_url" class="data-cards__text data-cards__text--theme data-cards__text--double"><img src="@/assets/img/profile/check-theme-icon.svg" alt=""><b>Скан копия документа в наличии</b></div>
        <div v-else class="data-cards__text data-cards__text--light data-cards__text--double"><img src="@/assets/img/profile/not-icon.svg" alt=""><b>Скана документа нет в наличии</b></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileDocumentsList',
  data() {
    return {
      documents_data: {}
    }
  },
  mounted() {
    this.$store.dispatch('profile/documents/GET_LIST')
      .then(response => {
        this.documents_data = response.data
      })
  },
}
</script>
